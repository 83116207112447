import createAuth0Client from '@auth0/auth0-spa-js';

async function configureAuth0() {
  const auth0 = await createAuth0Client({
    domain: 'whodatahub.eu.auth0.com',
    client_id: 'usFAfkd6D0KxUF22sTfDCwLyCksacI9C'
  });

  const isAuthenticated = await auth0.isAuthenticated();

  window.logout = () => {
    auth0.logout({
      returnTo: window.location.origin
    });
  }

  if (isAuthenticated) {
  	return import("./start");
  }

	const query = window.location.search;

  if (query.includes("code=") && query.includes("state=")) {
    // Process the login state
    await auth0.handleRedirectCallback();
    
    // Use replaceState to redirect the user away and remove the querystring parameters
    window.history.replaceState({}, document.title, "/");

    return import("./start");
  }

  await auth0.loginWithRedirect({
    redirect_uri: window.location.origin
  });  	
}

configureAuth0();
